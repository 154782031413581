import { useField, useFormikContext } from 'formik';
import Select from 'react-select';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import InputInfo from './parts/InputInfo';
import { inputClasses } from '../../inc/utils';
import { useEffect, useRef, useState } from 'react';
import useQueryParams from '../../hooks/useQueryParams';

const SelectInputNEW = ({
    label,
    description = '',
    info = '',
    className = '',
    syncQuery = false,
    onQuerySync = null,
    placeholder = '',
    disabled = false,
    options = null,
    ...props
}) => {
    const { getQueryParam, setQueryParams } = useQueryParams();
    const [field, meta] = useField(props);
    const [prevValue, setPrevValue] = useState(field.value);
    const { setFieldValue } = useFormikContext();
    const defaultValueSet = useRef(false);
    const [selectOptions, setSelectOptions] = useState([]);

    // Set initial value from query
    useEffect(() => {
        if (defaultValueSet.current) return;
        defaultValueSet.current = true;

        if (syncQuery === true) {
            const value = getQueryParam(props.name);
            if (value) {
                setFieldValue(props.name, value);
                if (onQuerySync) {
                    onQuerySync(value);
                }
            }
        }
    }, [
        props.name,
        field,
        syncQuery,
        setFieldValue,
        getQueryParam,
        onQuerySync,
    ]);

    // Sync query
    useEffect(() => {
        if (prevValue !== field.value) {
            setPrevValue(field.value);

            if (syncQuery === true) {
                setQueryParams({ [props.name]: field.value });
            }
        }
    }, [field.value, props.name, syncQuery, prevValue, setQueryParams]);

    // Add options handling
    useEffect(() => {
        setSelectOptions(
            options
                ? options
                : props?.children?.map(option => ({
                      value: option.props.value,
                      label: option.props.children,
                  }))
        );
    }, [options, props.children]);

    // Add select styles
    const customSelectStyles = {
        control: (base, state) => ({
            ...base,
            boxShadow: 'none',
            borderColor: state.isFocused ? '#017cef' : '#D1D5DB',
            '&:hover': {
                borderColor: '#D1D5DB',
            },
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            padding: '8px 10px 8px 4px',
            ...(disabled && {
                backgroundColor: '#F3F4F6',
                color: '#D1D5DB',
                borderColor: '#D1D5DB',
            }),
        }),
        // ... other style properties from Select2Input ...
        indicatorSeparator: (base, state) => ({
            display: 'none',
        }),
        dropdownIndicator: (base, state) => {
            return {
                ...base,
                display: 'inline-flex',
                alignItems: 'center',
                padding: '0.5rem 0',
                color: 'rgb(107, 114, 128)',
                height: '1rem',
                width: '1rem',
            };
        },
        clearIndicator: (base, state) => {
            return {
                ...base,
                padding: 6,
            };
        },
        placeholder: (base, state) => ({
            ...base,
            fontSize: '14px',
        }),
        valueContainer: (base, state) => ({
            ...base,
            padding: '0 0.5rem',
           //maxHeight: '24px',
        }),
        singleValue: (base, state) => ({
            ...base,
            fontSize: '14px',
        }),
        input: (base, state) => {
            return {
                ...base,
                lineHeight: '1',
                fontSize: '14px',
                margin: 0,
                padding: '0px',
                outline: 'none',
                outlineOffset: 0,
                '& input': {
                    boxShadow: 'none !important',
                },
            };
        },
        menu: (base, state) => {
            return {
                ...base,
                zIndex: '2',
            };
        },
    };

    return (
        <InputContainer className={className}>
            {label ? (
                <InputLabel htmlFor={props.id || props.name}>
                    {label}
                </InputLabel>
            ) : (
                ''
            )}

            {description ? (
                <InputDescription>{description}</InputDescription>
            ) : (
                ''
            )}
            <Select
                styles={customSelectStyles}
                {...field}
                options={selectOptions}
                value={selectOptions.find(option => option.value === field.value)}
                placeholder={placeholder || 'Select...'}
                className="input-field input-field--select"
                isDisabled={disabled}
                onChange={selectedOption => {
                    const value = selectedOption ? selectedOption.value : '';
                    setFieldValue(props.name, value);
                    if (onQuerySync) {
                        onQuerySync(value);
                    }
                }}
                isSearchable={false}
            />
            {info && <InputInfo>{info}</InputInfo>}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
};

export default SelectInputNEW;
