import { Suspense } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
} from 'react-router-dom';
import './firebase.config';

//Intercom Wrapper
import IntercomWrapper from './components/IntercomWrapper/IntercomWrapper';

// Providers
import { GlobalProvider } from './context/GlobalContext';
import { AuthStatusProvider } from './context/AuthContext';
import { ModalProvider } from './core-ui/Modals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GAProvider } from './context/GAContext';
import { SlideOutProvider } from './core-ui/SlideOut/SlideOut';

// Routes
import PrivateRoute from './routes/PrivateRoute';

// Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Page loader
import { PageLoader } from './core-ui/Loaders';

// Layout
import Dashboard from './components/Dashboard';
import NotFound from './pages/NotFound';

// Entry pages
import Home from './pages/Home';

//HubSpot Tracker
import { HubSpotTracker, HubSpotWrapper } from './components/HubSpotTracker';

// Global pages
import Settings from './pages/Settings';
import { lazyWithPreload } from 'react-lazy-with-preload';
import RedirectRoute from './routes/RedirectRoute';

const ProPreview = lazyWithPreload(() => import('./pages/ProPreview'));

// Public pages
const Apply = lazyWithPreload(() => import('./pages/Apply/Apply'));
const Hire = lazyWithPreload(() => import('./pages/Hire'));
const ScheduleCall = lazyWithPreload(() => import('./pages/ScheduleCall'));

// New auth
const SignUp = lazyWithPreload(() => import('./pages/Auth/SignUp'));
const VerificationSent = lazyWithPreload(() =>
    import('./pages/Auth/VerificationSent')
);
const FinishSignUp = lazyWithPreload(() => import('./pages/Auth/FinishSignUp'));
const FinishSetup = lazyWithPreload(() => import('./pages/Auth/FinishSetup'));
const FinishSocialSignUp = lazyWithPreload(() =>
    import('./pages/Auth/FinishSocialSignUp')
);
const SignIn = lazyWithPreload(() => import('./pages/Auth/SignIn'));
const ForgotPassword = lazyWithPreload(() =>
    import('./pages/Auth/ForgotPassword')
);
const ResetPassword = lazyWithPreload(() =>
    import('./pages/Auth/ResetPassword')
);

// Helpers
const Helpers = lazyWithPreload(() => import('./pages/Helpers'));

// Manager pages
const Overview = lazyWithPreload(() => import('./pages/Admin/Overview'));
const Skills = lazyWithPreload(() => import('./pages/Admin/Skills'));

const Professionals = lazyWithPreload(() =>
    import('./pages/Admin/Professionals')
);
const Professional = lazyWithPreload(() =>
    import('./pages/Admin/Professional')
);
const Engagements = lazyWithPreload(() =>
    import('./pages/Admin/Engagements/Engagements1')
);
const NewEngagement = lazyWithPreload(() =>
    import('./pages/Admin/NewEngagement')
);
const NewMeeting = lazyWithPreload(() => import('./pages/Admin/NewMeeting'));
const EngagementAdmin = lazyWithPreload(() =>
    import('./pages/Admin/Engagement')
);
const AdminHelpers = lazyWithPreload(() => import('./pages/Admin/Helpers'));
const Clients = lazyWithPreload(() => import('./pages/Admin/Clients/Clients1'));
const SingleClient = lazyWithPreload(() =>
    import('./pages/Admin/SingleClient')
);
const NewClient = lazyWithPreload(() => import('./pages/Admin/NewClient'));
const NewProfessional = lazyWithPreload(() =>
    import('./pages/Admin/NewProfessional')
);
const NewRequestAdminSide = lazyWithPreload(() =>
    import('./pages/Admin/NewRequest')
);
const Managers = lazyWithPreload(() => import('./pages/Admin/Managers'));
const NewManager = lazyWithPreload(() => import('./pages/Admin/NewManager'));
const SingleManager = lazyWithPreload(() =>
    import('./pages/Admin/SingleManager')
);
const Export = lazyWithPreload(() => import('./pages/Admin/Export'));
const Positions = lazyWithPreload(() => import('./pages/Admin/Positions'));
const Position = lazyWithPreload(() => import('./pages/Admin/Position'));
const Packages = lazyWithPreload(() => import('./pages/Admin/Packages'));
const Package = lazyWithPreload(() => import('./pages/Admin/Package'));

// Pro pages
//const ProDashboard = lazyWithPreload(() => import('./pages/Pro/Dashboard'));
const ProProfile = lazyWithPreload(() => import('./pages/Pro/Profile'));
const ProEngagements = lazyWithPreload(() => import('./pages/Pro/Engagements'));
const ProNotActive = lazyWithPreload(() => import('./pages/Pro/NotActive'));
const ProNotifications = lazyWithPreload(() =>
    import('./pages/Pro/Notifications')
);
const ProTimeTracking = lazyWithPreload(() =>
    import('./pages/Pro/TimeTracking')
);
// const ProStatus = lazyWithPreload(() => import('./pages/pro/ProStatus'));
// const ProProfile = lazyWithPreload(() => import('./pages/pro/ProProfile'));
// const ProEngagements = lazyWithPreload(() => import('./pages/pro/ProEngagements'));

// Client pages
const ClientHelpers = lazyWithPreload(() =>
    import('./pages/Client/ClientHelpers')
);

const Team = lazyWithPreload(() => import('./pages/Client/Team'));
const MSA = lazyWithPreload(() => import('./pages/Client/MSA'));
const Thankyou = lazyWithPreload(() => import('./pages/Client/Thankyou'));
const NewRequest = lazyWithPreload(() => import('./pages/Client/NewRequest'));
const PaymentMethods = lazyWithPreload(() =>
    import('./pages/Client/PaymentMethods/PaymentMethods')
);
const NewPaymentMethod = lazyWithPreload(() =>
    import('./pages/Client/NewPaymentMethod')
);
const Billing = lazyWithPreload(() => import('./pages/Client/Billing'));
const Invoices = lazyWithPreload(() => import('./pages/Client/Invoices'));
const Invoice = lazyWithPreload(() => import('./pages/Client/Invoice'));
const Balances = lazyWithPreload(() => import('./pages/Client/Balances'));

const SetupAccount = lazyWithPreload(() =>
    import('./pages/Client/SetupAccount')
);
const Checkout = lazyWithPreload(() => import('./pages/Client/Checkout'));
const PaymentSuccess = lazyWithPreload(() =>
    import('./pages/Client/PaymentSuccess')
);
const Refer = lazyWithPreload(() => import('./pages/Client/Refer'));
const SignSow = lazyWithPreload(() => import('./pages/Client/SignSow'));

const FindPro = lazyWithPreload(() => import('./pages/Client/FindPro'));
const ProDetails = lazyWithPreload(() => import('./pages/Client/ProDetails'));
const ProBookCall = lazyWithPreload(() => import('./pages/Client/ProBookCall'));
const EngagementDetails = lazyWithPreload(() =>
    import('./pages/Client/EngagementDetails')
);
const ProHire = lazyWithPreload(() => import('./pages/Client/ProHire'));
const UpgradeEngagement = lazyWithPreload(() =>
    import('./pages/Client/UpgradeEngagement')
);
const UpgradeCheckout = lazyWithPreload(() =>
    import('./pages/Client/UpgradeCheckout')
);
const UpgradePaymentSuccess = lazyWithPreload(() =>
    import('./pages/Client/UpgradePaymentSuccess')
);

function App() {
    // useEffect(() => {
    //     if (process.env.REACT_APP_DEPLOY_ENV === 'production') {
    //         const script = document.createElement('script');
    //         script.type = 'text/javascript';
    //         script.id = 'hs-script-loader';
    //         script.async = true;
    //         script.defer = true;
    //         script.src = '//js.hs-scripts.com/46060550.js';
    //         document.head.appendChild(script);
    //     }
    // }, []);

    return (
        <div className="App prose">
            <AuthStatusProvider>
                <HubSpotWrapper>
                    <SlideOutProvider>
                        <ModalProvider>
                            <Router>
                                {process.env.REACT_APP_DEPLOY_ENV ===
                                    'production' && <HubSpotTracker />}
                                <GlobalProvider>
                                    <GAProvider>
                                        <Suspense fallback={<PageLoader />}>
                                            <Routes>
                                                <Route
                                                    element={<RedirectRoute />}
                                                >
                                                    <Route
                                                        path="/"
                                                        element={<Home />}
                                                    />
                                                    {/* Pro preview */}
                                                    <Route
                                                        path="pro/:id"
                                                        element={<ProPreview />}
                                                    />
                                                    {/* User not logged in */}
                                                    <Route
                                                        element={
                                                            <PrivateRoute
                                                                shouldBeLoggedIn={
                                                                    false
                                                                }
                                                                redirectTo=""
                                                            />
                                                        }
                                                    >
                                                        <Route
                                                            path="sign-up"
                                                            element={<SignUp />}
                                                        />
                                                        <Route
                                                            path="verification-sent"
                                                            element={
                                                                <VerificationSent />
                                                            }
                                                        />
                                                        <Route
                                                            path="finish-sign-up"
                                                            element={
                                                                <FinishSignUp />
                                                            }
                                                        />
                                                        <Route
                                                            path="finish-social-sign-up"
                                                            element={
                                                                <FinishSocialSignUp />
                                                            }
                                                        />
                                                        <Route
                                                            path="sign-in"
                                                            element={<SignIn />}
                                                        />
                                                        <Route
                                                            path="login"
                                                            element={<SignIn />}
                                                        />
                                                        <Route
                                                            path="forgot-password"
                                                            element={
                                                                <ForgotPassword />
                                                            }
                                                        />
                                                        <Route
                                                            path="reset-password"
                                                            element={
                                                                <ResetPassword />
                                                            }
                                                        />
                                                        <Route
                                                            path="setup"
                                                            element={
                                                                <SetupAccount />
                                                            }
                                                        />
                                                        <Route
                                                            path="apply/:refId"
                                                            element={
                                                                <GoogleReCaptchaProvider
                                                                    reCaptchaKey={
                                                                        process
                                                                            .env
                                                                            .REACT_APP_RECAPTCHA_SITE_KEY
                                                                    }
                                                                >
                                                                    <Apply />
                                                                </GoogleReCaptchaProvider>
                                                            }
                                                        />
                                                        <Route
                                                            path="hire"
                                                            element={
                                                                <GoogleReCaptchaProvider
                                                                    reCaptchaKey={
                                                                        process
                                                                            .env
                                                                            .REACT_APP_RECAPTCHA_SITE_KEY
                                                                    }
                                                                >
                                                                    <Hire />
                                                                </GoogleReCaptchaProvider>
                                                            }
                                                        />
                                                        <Route
                                                            path="schedule-call"
                                                            element={
                                                                <GoogleReCaptchaProvider
                                                                    reCaptchaKey={
                                                                        process
                                                                            .env
                                                                            .REACT_APP_RECAPTCHA_SITE_KEY
                                                                    }
                                                                >
                                                                    <ScheduleCall />
                                                                </GoogleReCaptchaProvider>
                                                            }
                                                        />

                                                        {/* Remove helpers to admin later */}
                                                        <Route
                                                            path="helpers"
                                                            element={
                                                                <Helpers />
                                                            }
                                                        />
                                                    </Route>
                                                    {/* Clients setup */}
                                                    <Route
                                                        element={
                                                            <PrivateRoute
                                                                roles={[
                                                                    'client',
                                                                ]}
                                                                status={[
                                                                    'setup',
                                                                ]}
                                                            />
                                                        }
                                                    >
                                                        <Route
                                                            path="finish-setup"
                                                            element={
                                                                <FinishSetup />
                                                            }
                                                        />
                                                    </Route>
                                                    {/* Pro role + inactive status */}
                                                    <Route
                                                        element={
                                                            <PrivateRoute
                                                                roles={['pro']}
                                                                status={[
                                                                    'inactive',
                                                                ]}
                                                            />
                                                        }
                                                    >
                                                        <Route
                                                            path="status"
                                                            element={
                                                                <ProNotActive />
                                                            }
                                                        />
                                                    </Route>
                                                    {/* All roles */}
                                                    <Route
                                                        element={
                                                            <PrivateRoute
                                                                status={[
                                                                    'active',
                                                                    'pending',
                                                                ]}
                                                            />
                                                        }
                                                    >
                                                        <Route
                                                            element={
                                                                <Dashboard />
                                                            }
                                                        >
                                                            <Route
                                                                path="*"
                                                                element={
                                                                    <NotFound />
                                                                }
                                                            />
                                                            <Route
                                                                element={
                                                                    <IntercomWrapper />
                                                                }
                                                            >
                                                                <Route
                                                                    path="settings"
                                                                    element={
                                                                        <Settings />
                                                                    }
                                                                />

                                                                {/* Manager role */}
                                                                <Route
                                                                    element={
                                                                        <PrivateRoute
                                                                            roles={[
                                                                                'manager',
                                                                                'admin',
                                                                                'sales',
                                                                                'recruiter',
                                                                            ]}
                                                                        />
                                                                    }
                                                                >
                                                                    <Route
                                                                        path="d"
                                                                        element={
                                                                            <Outlet />
                                                                        }
                                                                    >
                                                                        <Route
                                                                            path="overview"
                                                                            element={
                                                                                <Overview />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="skills"
                                                                            element={
                                                                                <Skills />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="positions"
                                                                            element={
                                                                                <Positions />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="position/:id"
                                                                            element={
                                                                                <Position />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="packages"
                                                                            element={
                                                                                <Packages />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="package/:id"
                                                                            element={
                                                                                <Package />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="engagements"
                                                                            element={
                                                                                <Engagements />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="new-request"
                                                                            element={
                                                                                <NewRequestAdminSide />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="new-engagement"
                                                                            element={
                                                                                <NewEngagement />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="new-meeting"
                                                                            element={
                                                                                <NewMeeting />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="engagement/:id"
                                                                            element={
                                                                                <EngagementAdmin />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="professionals"
                                                                            element={
                                                                                <Professionals
                                                                                    preload={() => {
                                                                                        ProDetails.preload();
                                                                                        EngagementDetails.preload();
                                                                                        ProHire.preload();
                                                                                        Checkout.preload();
                                                                                    }}
                                                                                />
                                                                            }
                                                                        >
                                                                            <Route
                                                                                path="view/:proId"
                                                                                element={
                                                                                    <ProDetails parentPage="/d/professionals" />
                                                                                }
                                                                            />
                                                                        </Route>

                                                                        <Route
                                                                            path="professional/new"
                                                                            element={
                                                                                <NewProfessional />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="professionals/edit/:proId"
                                                                            element={
                                                                                <Professional />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="clients"
                                                                            element={
                                                                                <Clients />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="clients/:clientId"
                                                                            element={
                                                                                <Clients />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="client/new"
                                                                            element={
                                                                                <NewClient />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="clients/edit/:clientId"
                                                                            element={
                                                                                <SingleClient />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="managers"
                                                                            element={
                                                                                <Managers />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="manager/new"
                                                                            element={
                                                                                <NewManager />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="managers/edit/:id"
                                                                            element={
                                                                                <SingleManager />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="helpers"
                                                                            element={
                                                                                <AdminHelpers />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="export"
                                                                            element={
                                                                                <Export />
                                                                            }
                                                                        />
                                                                    </Route>
                                                                </Route>

                                                                {/* Client role */}
                                                                <Route
                                                                    element={
                                                                        <PrivateRoute
                                                                            roles={[
                                                                                'client',
                                                                            ]}
                                                                            status={[
                                                                                'active',
                                                                            ]}
                                                                        />
                                                                    }
                                                                >
                                                                    <Route
                                                                        element={
                                                                            <ClientHelpers />
                                                                        }
                                                                    >
                                                                        <Route
                                                                            path="thankyou"
                                                                            element={
                                                                                <Thankyou />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="team"
                                                                            element={
                                                                                <Team
                                                                                    preload={() => {
                                                                                        ProDetails.preload();
                                                                                        EngagementDetails.preload();
                                                                                        ProHire.preload();

                                                                                        Checkout.preload();
                                                                                        UpgradeEngagement.preload();
                                                                                        UpgradeCheckout.preload();
                                                                                    }}
                                                                                />
                                                                            }
                                                                        >
                                                                            <Route
                                                                                path="engagement/:id"
                                                                                element={
                                                                                    <EngagementDetails />
                                                                                }
                                                                            >
                                                                                <Route
                                                                                    path="sow"
                                                                                    element={
                                                                                        <SignSow />
                                                                                    }
                                                                                />
                                                                            </Route>

                                                                            <Route
                                                                                path="pro/:proId"
                                                                                element={
                                                                                    <ProDetails parentPage="/team" />
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path="hire/:engagementId"
                                                                                element={
                                                                                    <ProHire />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path="checkout/:engagementId"
                                                                                element={
                                                                                    <Checkout />
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path="upgrade/:engagementId"
                                                                                element={
                                                                                    <UpgradeEngagement />
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path="upgrade-checkout/:engagementId"
                                                                                element={
                                                                                    <UpgradeCheckout />
                                                                                }
                                                                            />
                                                                        </Route>

                                                                        <Route
                                                                            path="upgrade-payment-success/:engagementId"
                                                                            element={
                                                                                <UpgradePaymentSuccess />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="payment-success"
                                                                            element={
                                                                                <PaymentSuccess />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="new-request"
                                                                            element={
                                                                                <NewRequest />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="find-pro"
                                                                            element={
                                                                                <FindPro
                                                                                    preload={() => {
                                                                                        ProDetails.preload();
                                                                                        ProBookCall.preload();
                                                                                    }}
                                                                                />
                                                                            }
                                                                        >
                                                                            <Route
                                                                                path=":proId"
                                                                                element={
                                                                                    <ProDetails parentPage="/find-pro" />
                                                                                }
                                                                            >
                                                                                <Route
                                                                                    path="book-call"
                                                                                    element={
                                                                                        <ProBookCall />
                                                                                    }
                                                                                />
                                                                            </Route>
                                                                        </Route>

                                                                        <Route
                                                                            path="billing"
                                                                            element={
                                                                                <Billing
                                                                                    preload={() => {
                                                                                        Invoices.preload();
                                                                                        Invoice.preload();
                                                                                        PaymentMethods.preload();
                                                                                        NewPaymentMethod.preload();
                                                                                    }}
                                                                                />
                                                                            }
                                                                        >
                                                                            <Route
                                                                                path="invoices"
                                                                                element={
                                                                                    <Invoices />
                                                                                }
                                                                            >
                                                                                <Route
                                                                                    path={
                                                                                        ':id'
                                                                                    }
                                                                                    element={
                                                                                        <Invoice />
                                                                                    }
                                                                                />
                                                                            </Route>
                                                                            <Route
                                                                                path="payment-methods"
                                                                                element={
                                                                                    <PaymentMethods />
                                                                                }
                                                                            >
                                                                                <Route
                                                                                    path="new"
                                                                                    element={
                                                                                        <NewPaymentMethod />
                                                                                    }
                                                                                />
                                                                            </Route>
                                                                            <Route
                                                                                path="balances"
                                                                                element={
                                                                                    <Balances />
                                                                                }
                                                                            />
                                                                        </Route>

                                                                        <Route
                                                                            path="refer"
                                                                            element={
                                                                                <Refer />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="msa"
                                                                            element={
                                                                                <MSA />
                                                                            }
                                                                        />
                                                                    </Route>
                                                                </Route>
                                                                {/* Pro role + 'pending' and 'active' status */}
                                                                <Route
                                                                    element={
                                                                        <PrivateRoute
                                                                            roles={[
                                                                                'pro',
                                                                            ]}
                                                                            status={[
                                                                                'active',
                                                                                'pending',
                                                                            ]}
                                                                        />
                                                                    }
                                                                >
                                                                    <Route
                                                                        path="engagements"
                                                                        element={
                                                                            <ProEngagements />
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path="pro"
                                                                        element={
                                                                            <ProEngagements />
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path="profile"
                                                                        element={
                                                                            <ProProfile />
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path="notifications"
                                                                        element={
                                                                            <ProNotifications />
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path="time-records"
                                                                        element={
                                                                            <ProTimeTracking />
                                                                        }
                                                                    />
                                                                </Route>
                                                            </Route>
                                                        </Route>
                                                        {/* End of Dashboard wrapper component */}
                                                    </Route>
                                                    <Route
                                                        path="*"
                                                        element={<NotFound />}
                                                    />
                                                </Route>
                                            </Routes>
                                        </Suspense>
                                    </GAProvider>
                                </GlobalProvider>
                            </Router>
                        </ModalProvider>
                    </SlideOutProvider>
                </HubSpotWrapper>
            </AuthStatusProvider>
            <ToastContainer
                position="top-right"
                closeOnClick
                pauseOnFocusLoss
                theme="light"
            />
        </div>
    );
}

export default App;
